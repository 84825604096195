.custom-styles {
  &-font-size-sm {
    font-size: $font-size-sm;
  }

  &-font-size-lg {
    font-size: $font-size-lg;
  }

  &-list-indicator {
    border-left: 3px solid rgba($primary, $btn-disabled-opacity);
    padding-left: $spacer;
  }
}
