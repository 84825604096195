/* stylelint-disable */

// grayscale

$black: rgb(25, 25, 25);
$white: rgb(255, 255, 255);

$gray: rgb(118, 118, 118);
$gray-light: mix($white, $gray, 55%);
$gray-lighter: mix($white, $gray, 85%);
$gray-lightest: mix($white, $gray, 95%);


// base colors

$cta: rgb(0, 153, 146);
$cta-light: mix($white, $cta, 65%);
$cta-dark: mix($black, $cta, 25%);

$primary: rgb(71, 116, 148);
$primary-light: mix($white, $primary, 65%);
$primary-dark: mix($black, $primary, 25%);

$brand: rgb(0, 105, 180);
$brand-light: mix($white, $brand, 95%);
$brand-dark: mix($black, $brand, 25%);


// system colors

$success: rgb(39, 175, 122);
$success-light: mix($white, $success, 55%);
$success-lighter: mix($white, $success, 85%);
$success-dark: mix($black, $success, 25%);

$warning: rgb(255, 188, 53);
$warning-light: mix($white, $warning, 85%);
$warning-dark: mix($black, $warning, 25%);

$danger: rgb(236, 95, 101);
$danger-light: mix($white, $danger, 85%);
$danger-dark: mix($black, $danger, 25%);


$colors: (
  "black": $black,
  "white": $white,
  "gray": $gray,
  "gray-light": $gray-light,
  "gray-lighter": $gray-lighter,
  "gray-lightest": $gray-lightest,
  "cta": $cta,
  "cta-light": $cta-light,
  "cta-dark": $cta-dark,
  "primary": $primary,
  "primary-light": $primary-light,
  "primary-dark": $primary-dark,
  "brand": $brand,
  "brand-light": $brand-light,
  "brand-dark": $brand-dark,
  "success": $success,
  "success-light": $success-light,
  "success-lighter": $success-lighter,
  "success-dark": $success-dark,
  "warning": $warning,
  "warning-light": $warning-light,
  "warning-dark": $warning-dark,
  "danger": $danger,
  "danger-light": $danger-light,
  "danger-dark": $danger-dark
);
