@import '../../css/bootstrap/custom/css/functions';
@import '../../css/bootstrap/custom/css/variables';

.input {
  color: $black !important;
  border-color: theme-color-level(brand, -3);

  &::placeholder {
    color: theme-color-level(brand, 3);
  }

  &,
  &:focus {
    background-color: theme-color-level(brand, -3);
  }

  /* For Firefox */
  -moz-appearance: textfield;

  /* Webkit browsers like Safari and Chrome */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.button {
  border-color: theme-color-level(brand, 3);
  background-color: theme-color-level(brand, 3);
}
