@import '../../css/bootstrap/custom/css/functions';
@import '../../css/bootstrap/custom/css/variables';

.actionCard {
  cursor: pointer;

  &:hover {
    background-color: theme-color-level(primary, -11) !important;
  }
}
