/* stylelint-disable */

@mixin font-src($file-name-remote) {
  font-family: 'LL Circular Pro';
  src: url('../fonts/ll-circular-pro/#{$file-name-remote}.eot'); // IE9 Compat Modes
  src: url('../fonts/ll-circular-pro/#{$file-name-remote}.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/ll-circular-pro/#{$file-name-remote}.woff2') format('woff2'),
    url('../fonts/ll-circular-pro/#{$file-name-remote}.woff') format('woff');
}

@font-face {
  @include font-src('lineto-circular-pro-book');

  font-style: normal;
  font-weight: 400;
}

@font-face {
  @include font-src('lineto-circular-pro-medium');

  font-style: normal;
  font-weight: 500;
}

@font-face {
  @include font-src('lineto-circular-pro-bold');

  font-style: normal;
  font-weight: 600;
}
