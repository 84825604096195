@import '../../css/bootstrap/custom/css/functions';
@import '../../css/bootstrap/custom/css/mixins';
@import '../../css/bootstrap/custom/css/variables';

.descriptionList {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: $spacer * 2;
  row-gap: $spacer * 2;
}

@include media-breakpoint-up(md) {
  .descriptionList {
    grid-template-columns: repeat(2, 1fr);
  }
}

@include media-breakpoint-up(xl) {
  .descriptionList {
    grid-template-columns: 4fr 2fr 2fr 1fr;
  }
}
