@import '../../css/bootstrap/custom/css/functions';
@import '../../css/bootstrap/custom/css/variables';

.vorherContent {
  background-color: rgba(theme-color('success'), 0.25);
}

.jetztContent {
  background-color: rgba(theme-color('danger'), 0.25);
}
