@import '../../css/bootstrap/custom/css/functions';
@import '../../css/bootstrap/custom/css/variables';

$navLevel1Height: $spacer * 4.5;
$productNameFontSize: 1.375rem;
$secondaryColor: theme-color-level(brand, -2);
$productNamePaddingTop: (($navLevel1Height - $productNameFontSize) / 2);

.header {
  line-height: 1.5rem;
  z-index: 1;
}

.productName {
  font-size: $productNameFontSize;
  padding: $productNamePaddingTop ($spacer * 3) $productNamePaddingTop 0;
}

.link {
  &:hover,
  &:active,
  &:global(.active) {
    color: $white;
    background-color: $secondaryColor;
    text-decoration: none;
  }
}
