/* stylelint-disable */
label, dt {
  font-size: $font-size-sm;
  font-weight: 400;
  color: $headings-color;
  line-height: 1.5rem;
}

th {
  font-weight: 400;
}
