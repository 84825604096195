@import '../../css/bootstrap/custom/css/functions';
@import '../../css/bootstrap/custom/css/variables';

.alert {
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.visible {
  opacity: 1;
  transform: translateY(0);
}

.invisible {
  opacity: 0;
  transform: translateY(-100%);
}

.wrapper {
  top: 100%;
  pointer-events: none;
}
