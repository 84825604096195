@import '../../css/bootstrap/custom/css/functions';
@import '../../css/bootstrap/custom/css/variables';

.nav {
  color: theme-color-level(primary, -8);

  button {
    all: unset;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
