dd {
  margin-bottom: 0;
}

address {
  margin-bottom: 0;
}

body {
  background-color: #f8f8f8;
}
