/* stylelint-disable */
@import "pixel-to-rem";
@import "colors";

$blue: $brand;
$green: $success;
$yellow: $warning;
$red: $danger;

$body-color: $gray;
$headings-color: $black;
$border-color: $gray-lighter;
$bg-light: $gray-lightest;
$btn-border-color: $gray-lighter;
$text-muted: $gray-light;

$font-weight-bold: 700; // is the default, but we need it in this file
$line-height-base: 1.5; // is default, but we need it in this file

$spacer: 1rem;
$spacers: (
  0: 0,
  // 4px
    1: $spacer * 0.25,
  // 8px
    2: $spacer * 0.5,
  // 16px
    3: $spacer,
  // 24px
    4: $spacer * 1.5,
  // 32px
    5: $spacer * 2,
  // 48px
    6: $spacer * 3
);

$theme-colors: (
  'cta': $cta,
  'brand': $brand,
);

$paragraph-margin-bottom: 0;

$font-family-sans-serif: 'LL Circular Pro', 'Helvetica Neue', Helvetica, Arial,
  Sans-Serif;

$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.25 !default;
$font-size-sm:                $font-size-base * .875 !default;
$small-font-size: $font-size-sm;

$h1-font-size: 2rem; // 32px
$h2-font-size: 1.375rem; // 22px
$h3-font-size: 1.125rem; // 18px
$h4-font-size: 1rem;
$h5-font-size: 0.875rem;
$h6-font-size: 0.75rem;

$border-radius: .25rem;
$border-radius-lg: .25rem;
$border-radius-sm: .25rem;

$headings-margin-bottom: 0;


// Buttons

$btn-padding-y:               px-to-rem(7px); // 1px substracted due to border
$btn-padding-x:               px-to-rem(23px); // 1px substracted due to border
$btn-font-size:               $font-size-sm;
$btn-line-height:             px-to-rem(24px);

$btn-padding-y-sm:            px-to-rem(3px); // 1px substracted due to border
$btn-padding-x-sm:            px-to-rem(15px); // 1px substracted due to border
$btn-font-size-sm:            $font-size-base * 0.75;
$btn-line-height-sm:          px-to-rem(24px);

$btn-padding-y-lg:            px-to-rem(15px); // 1px substracted due to border
$btn-padding-x-lg:            px-to-rem(31px); // 1px substracted due to border
$btn-font-size-lg:            $font-size-base;
$btn-line-height-lg:          px-to-rem(24px);

// Tables

$table-head-color: $body-color;
$table-head-bg: $bg-light;


// $form-group-margin-bottom: 2rem;

// $navbar-brand-font-size: ($font-size-base * 1.5);

// $modal-md: 600px;
@import '../../scss/variables';
$theme-colors: map-remove($theme-colors, "secondary", "info", "light", "dark");
