/* stylelint-disable */
@import "../../scss/tables";

.table {
  thead th {
    border-bottom: (1 * $table-border-width) solid $table-border-color;
  }
}

.table-bordered {
  thead {
    th,
    td {
      border-bottom-width: 1 * $table-border-width;
    }
  }
}
